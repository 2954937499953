import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import * as yup from "yup";
import { parse } from "qs";
import {
  sendOpenMessage,
  createAccount,
  getNewsDetail,
} from "../services";
import Utils from "../utils";
import Layout from "../components/Layout";
import CutDown from "../components/CutDown";
import styles from "./styles.module.scss";
import { linkConfig, sourceUrl } from "../config";
import { setSessionStore, getSessionStore } from "../utils";

const _url = getSessionStore(sourceUrl);
const href = window.location.href;
let trackParameters = "";

export default () => {
  const formRef = useRef(null);
  // 发送验证码
  const [sendCode, setSendCode] = useState(false);
  // 获取signature
  const [signature, setSignature] = useState('');
  // 切换密码
  const [passwordVisible, setPasswordVisible] = useState(false);
  // 追踪参数
  const [searchParams, setSearchParams] = useState({});
  const [state, setState] = useState({
    agreementHTML: "",
    policyHTML: "",
  });
  //状态
  const [isSubmiting, setSubmiting] = useState(false);
  const [rules, setRules] = useState("");
  const history = useHistory();

  useEffect(() => {
    if (!_url) {
      setSessionStore(sourceUrl, href);

      const search = href.split("?")[1];

      const params = parse(search);
      setSearchParams({ ...params });
      trackParameters = search;
    } else {
      const search = _url.split("?")[1];
      const params = parse(search);
      setSearchParams({ ...params });
      trackParameters = search;
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const data = {};
        if (searchParams.policyId) {
          const res = await getNewsDetail(searchParams.policyId);
          if (res.code === 200 && Array.isArray(res.ch_msg) && res.ch_msg[0]) {
            data.policyHTML = res.ch_msg[0].content;
          }
        }
        if (searchParams.agreementId) {
          const res = await getNewsDetail(searchParams.agreementId);
          if (res.code === 200 && Array.isArray(res.ch_msg) && res.ch_msg[0]) {
            data.agreementHTML = res.ch_msg[0].content;
          }
        }
        if (searchParams.signature) {
          setSignature(searchParams.signature)
        }
        setState(data);
      } catch (error) {}
    })();
  }, [searchParams]);
  const [tips, setTips] = useState({
    type: null,
    msg: null,
  });
  const setMessage = useCallback(({ type, msg, time = 5 }) => {
    setTips({
      type,
      msg,
    });
    const timer = setTimeout(() => {
      setTips({ type: null, msg: null });
      clearTimeout(timer);
    }, time * 1000);
  }, []);

  const sendPhoneCaptcha = async (mobilePhone, validate) => {
    const param = {
      mobilePhone,
      validate,
      trackParameters,
    };
    if (signature) {param.signature = signature}
    const res = await sendOpenMessage(param);
    if (res.success && res.code === "OK") {
      setSendCode(true);
      setMessage({
        type: "success",
        msg: "发送验证码成功",
      });
    } else if (res.code !== "OK") {
      setMessage({
        type: "error",
        msg: res.msg,
      });
    }
  };
  const createRealAccount = useCallback(
    async (values) => {
      setSubmiting(true);
      const u = window.navigator.userAgent;
      const isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      let driverType = 1;
      if (isAndroid) {
        driverType = 2;
      } else if (isiOS) {
        driverType = 3;
      }
      const res = await createAccount({
        ...values,
        driverType,
        inviteCode: searchParams["inviteCode"],
        headers: {
          sourceUrl: _url || href,
        },
      });
      setSubmiting(false);
      if (res.code === "10036" || res.code === "10035") {
        history.replace("/monitor");
        return;
      }
      if (res.code !== "OK") {
        setMessage({
          type: "error",
          msg: res.msg,
        });
        return;
      }
      const store = window.sessionStorage;
      store && store.setItem("__r__", values.mobilePhone);
      store && store.setItem("__p__", values.password);
      const timer = setTimeout(() => {
        history.push(
          `/further-information/${res.data.updateCustomerUuid}${window.location.search}`
        );
        clearTimeout(timer);
      }, 0);
    },
    [searchParams, history, setMessage]
  );

  const showRules = useCallback((key) => {
    if (window.isNative || window.webkit || window.iOSNative) {
      setRules(key);
      return;
    }
    window.open(linkConfig[key]);
  }, []);

  useEffect(() => {
    const params = parse(window.location.search.slice(1));
    setSearchParams({ ...params });
  }, []);

  const platformTitle = useMemo(() => {
    return searchParams.platformName || "我司";
  }, [searchParams]);
  const agreementHTML = useMemo(() => {
    return (
      <div className="wrapper">
        <div className="bar">
          <h2>客户协议</h2>
          <span className="close" onClick={() => setRules("")}></span>
        </div>
        <p>
          {platformTitle}欢迎客户于其开设一个账户，并通过或透过客户在$
          {platformTitle}
          开设的账户不时向客户提供有关合约产品（如上文客户告鉴中所界定）的买卖。为此，$
          {platformTitle}及客户同意如下：
        </p>
        <h3>字词释义及标题</h3>
        <p>
          “{platformTitle}”一词包括{platformTitle}，其分部，继承人和转让人。
        </p>
        <p>“客户”一词指订立本协议的一方。</p>
        <p>
          “本协议”一词指客户与{platformTitle}
          就有关账户（等）之开设、维持及运作而订立及不时修定之协议或给予的授权，其中包括但不限于本客户协议、客户告鉴、风险披露声明、开户申请表及一切给予$
          {platformTitle}
          的授权。本协议的段落标题是为查考方便而加入的，因此并不限制或影响段落条文的应用与意义。
        </p>
        <h3>协议接受及约束效力</h3>
        <p>
          仅当{platformTitle}确认及审批客户之开户申请后，本协议方可视作已为$
          {platformTitle}所接受及成为客户与{platformTitle}
          之间双方同意并具有约束力的合同。本协议（包括本客户协议、客户告鉴、风险披露声明、开户申请及一切给予$
          {platformTitle}的授权）一经{platformTitle}
          如上文所接受将持续有效，并涵盖客户任何时候在{platformTitle}
          开立或重新开立的所有账户，不论{platformTitle}
          发生任何合并、兼并或其他变动。
        </p>
        <h3>交易授权</h3>
        <p>
          东环球可以与客户部分或全部的买卖指令下达市场。{platformTitle}
          获授权按照客户的口头、书面、传真或计算机网上指令向对手方如银行、金融机构或资深参与者为客户账户进行合约产品买卖。除非客户以书面形式作出反对，否则$
          {platformTitle}获授权向{platformTitle}
          认为适合之对手方如银行、金融机构或资深参与者执行所有订单。$
          {platformTitle}
          有权依据所有从客户经口头、书面、传真或计算机网上收到的指示或指令，包括其雇员、合伙人、法定负责人（”有关授权人”），只要$
          {platformTitle}没有收到客户通知有关授权人并没有获得授权。客户授权$
          {platformTitle}
          可依据和执行似是从客户或其授权人所得来的任何指示、授权或信息。因此客户同意
        </p>
        <p>
          （i）{platformTitle}
          获授权执行指示是不需查询有关指示的有效性，有关指示会被视为授权人发出的有效指令；
        </p>
        <p>
          （ii）在任何情况下，{platformTitle}
          不需核实指示的有效性或任何签名的真确性；
        </p>
        <p>
          (iii）在{platformTitle}
          秉承行事和没有疏忽的情况下，客户将承担所有由任何代表人、雇员、或授权人发出未经批准指示的后果及风险，客户将为任何损失、费用、酬金、损毁、经费、索赔、诉讼或要求（等）负责。客户并保证不向$
          {platformTitle}追究责任或要求赔偿，并且{platformTitle}
          不会因上述情况而导致损失，包括任何有关或产生自{platformTitle}
          的实际行动、延迟实际行动或拒绝采取行动、由客户提供给{platformTitle}
          的任何指示或资料，包括由客户的雇员、授权人或代表发出的不正当、未经授权、虚假或欺诈指示，即使该指示是没有获得客户授权。
        </p>
        <h3>执行订单酌情权</h3>
        <p>
          {platformTitle}有权限制客户每次下单的总数。{platformTitle}
          有权限制客户的任何时间可获得或可持有的持仓金额及／或总数。$
          {platformTitle}
          将按照客户计算机或录音电话的指示执行其选择接受的订单。$
          {platformTitle}有权拒绝接受任何订单或保证市场对冲。但是$
          {platformTitle}将不负责任何{platformTitle}
          不可直接或间接控制的事件、行为或不行为所造成的损失或损害，这种情况包括但不限于任何由于传输或通讯设施故障造成的订单或信息传输的延迟或不准确带来的损失或损害。
        </p>
        <h3>挂单交易平台执行机制</h3>
        <p>
          客户明白及同意，交易平台是依照自身的执行机制处理订单，当同一时间，多张订单都到达有效价位时，执行顺序并非按照到价先后，交易单的建立顺序也会是判断因素之一。凡牵涉平台处理同时到价交易单的顺序问题，客户同意以交易平台最终成交价位的处理机制为准，$
          {platformTitle}
          对交易平台的处理机制有最终解释权。下达某些挂单交易有可能并不见效或没有执行指定价位。如果订单是止损限价单，无法保证订单以限价执行而不需事先做出通知。客户同意不就因挂单而损失的潜在盈利追究$
          {platformTitle}的责任。
        </p>
        <h3>政府、对手机构及银行间系统规条</h3>
        <p>
          所有本协议下的交易均受辖于执行交易的对手机构或其他银行间市场（及其清算组织，如适用）的宪章、细则、条例、规定、习惯、用法、裁决和解释，并执行所有适合的加拿大法律。如果此后通过的任何法令，或任何政府机构通过的任何条款，或对本协议产生影响／冲突的任何条款，将视作被有关法令、条规变更或替代，而其他条款及变更后的条款将继续完全生效。客户承认本协议下的所有交易受辖于前述监管要求。
        </p>
        <h3>交叉交易的同意</h3>
        <p>
          客户在此承认并同意下述情况有可能出现，即与{platformTitle}
          相关的某一营业人员、董事、关联机构、关联人、雇员、银行或银行雇员、交易商及或$
          {platformTitle}
          本身可能是客户账户所进行的交易的对手、经纪人或委托人。客户在此同意进行上述交易时仅有的限制是有关执行买卖订单的银行、机构、交易所或交易委员会的任何可能的条例或规定，或其他监管机构的任何可能的限制与条件。
        </p>
        <h3>保证金和存取款</h3>
        <p>
          客户须向{platformTitle}提供并维持由{platformTitle}
          所不时订定的保证金金额或水平。根据市场状况，{platformTitle}
          有权在任何时候改变保证金要求。客户同意当{platformTitle}
          作出追补或增加保证金之要求时，客户须立即并迅速以{platformTitle}
          所要求的存款方式满足所有保证金催促通知的要求，而任何存款过程涉及的费用，包括银行手续费、汇率差价及所有有关费用均由客户承担。由于处理客户之存款需时，客户有可能不可以实时使用刚存入的保证金作为新持仓的按金，更可能不可以作为追加保证金，客户同意承担一切由于未能及时满足追加保证金要求而面临强制平仓之损失，其损失可能超过客户的初始保证金，即账户内的所有资金。$
          {platformTitle}
          可能在任何时候根据本协议的规定强制客户的账户进行平仓，即使$
          {platformTitle}不行使该项权利，并不代表其放弃该权利。任何$
          {platformTitle}过去的保证金要求均不妨碍{platformTitle}
          在不需提供任何理由或解释下提高上述保证金要求。客户有权随时通知$
          {platformTitle}
          提取指定的可用现金结余，客户同意任何汇款及转款过程涉及的费用，包括银行手续费、汇率差价及所有有关费用均由客户承担。客户完全理解$
          {platformTitle}
          及相关银行需要时间去处理有关客户之提款，客户很可能不可以实时收到所提取的金额。客户同意不追究$
          {platformTitle}
          由于未能及时满足其提款要求所引起的所有责任。当客户的提款要求一经确认，$
          {platformTitle}
          将实时从客户账户结余扣除该款额。客户必须确保其所指定的往来银行账户属于客户本人，$
          {platformTitle}均不接受第三方存取款。
        </p>
        <h3>账户清偿与欠款偿付</h3>
        <p>如发生任何一项或多项下列情形：</p>
        <p>i.客户身故、神经失常或司法宣定无能力； </p>
        <p>
          ii.客户申请破产，或选派托管人，或客户自愿或被动地或任何人向法庭申请其破产、清盘或其他相类似的法律程序；
        </p>
        <p>
          iii.客户的任何资产遭接管人或类似职位人员接管，或遭受任何扣押或判决之执行；
        </p>
        <p>iv.不论当时的市场报价如何，客户的账户保证金金额不足；</p>
        <p>
          iv.客户未能向{platformTitle}
          提供任何根据本协议要求的相关资料或信息；或 任何其他{platformTitle}
          应当采取保护措施的情况或变化，
        </p>
        <p>
          {platformTitle}
          拥有绝对酌情权在无须事前向客户作出要求提供保证金或追加保证金或任何通知下采取以下某种或多种行动:
        </p>
        <p>
          （i）{platformTitle}把其代客户托管或控制的资金或财产用作抵偿客户对$
          {platformTitle}负的一切债务；
        </p>
        <p>（ii）买卖任何客户在其账户中持有的合约产品持仓；</p>
        <p>
          （iii）取消任何或所有未完成订单，或其他任何以客户名义作出的承诺,
          不论涉及的利益是否客户独有或与他人合有。在上述买卖中，
          {platformTitle}
          有绝对酌情权通过任何银行间或其他经常进行业务的交易市场中进行，且$
          {platformTitle}可以购买全部或部分而不受赎回权的限制。只要$
          {platformTitle}
          向客户作出要求，客户将立即对其账户的债务负责，包括相应利息(即银行优惠利率加３℅或法律规定的最高利率，以较低为准)
          及其它相关费用。在其账户被{platformTitle}
          或客户自己全部或部分平仓时或在任何时候客户均须对其剩余欠款负责。如客户名下有多个户口，$
          {platformTitle}
          有绝对酌情权自行决定合并客户名下该些户口，把任何账户盈余抵销任何账户的债务或不足。
        </p>
        <h3>费用</h3>
        <p>
          客户将支付因{platformTitle}
          所提供的服务产生的手续费和其他特别服务之所有费用（包括但不限于溢价和折价，报表费，闲置账户费，指令取消费，转账费和其他费用）及收费（包括但不限于由银行间机构、银行、合约市场或其他监管或自律组织收取的费用）。$
          {platformTitle}可能不经通知收取佣金、费用及/或收费。客户同意向$
          {platformTitle}支付其欠交款项的利息。所有这些费用将在发生时，或在$
          {platformTitle}根据其绝对酌情权决定下由客户支付。客户同意$
          {platformTitle}从其账户中扣除上述费用。在客户指示{platformTitle}
          将其账户的未平仓持仓、资金、及/或财产转向其他机构时，客户同意支付由$
          {platformTitle}确定的转账费。{platformTitle}
          确认所有向客户报出的价格不包括溢价与折价。
        </p>
        <h3>套利</h3>
        <p>
          互联网、连线延误、报价上的误差或报价系统的漏洞有时会造成在$
          {platformTitle}
          交易平台上的报价无法准确地反映实时市场价格。「套利」、「切汇」及「剥头皮」，或因网络连接的延误或采用外挂软件(即任何非$
          {platformTitle}发布的第三方辅助软件) 进行快速的建仓和平仓操作
          而利用差价获利的行为(以下统称“套利”)，是不容许存在于客户直接向庄家进行买卖的场外交易市场中。$
          {platformTitle}
          不容许客户在其公司的交易平台上进行此等套利行为。任何客户利用潜在套利机会从事的交易可能会被取消。$
          {platformTitle}保留权利对涉及套利交易的账户进行必要的修正和调整。$
          {platformTitle}
          将拥有及依据绝对酌情权，要求交易员进行干预或核准所有下单以及或终止有关客户的账户，而不需事先通知客户。对套利或操控价格而产生的纠纷，$
          {platformTitle}将拥有及依据绝对酌情权进行解决。{platformTitle}
          保留冻结客户提款的权利直至能够完全解决上述的问题为止。于此处陈述的任何行动或决定并不免除或损害$
          {platformTitle}对客户和其雇员拥有之任何权利或赔偿。
        </p>
        <h3>价格、订单执行及平台的操控</h3>
        <p>
          {platformTitle}
          绝对严禁以任何形式对其价格、订单执行及平台进行操控(以下统称
          “操控”)。若{platformTitle}怀疑任何客户或账户从事操控，$
          {platformTitle}
          保留对该客户或有关账户进行调查及审核等的相关权利，并从涉嫌账户中扣除由相关活动所赚取的盈利款项。$
          {platformTitle}
          保留对相关账户进行必要更正或调整的权利。对于涉嫌从事操控的账户，$
          {platformTitle}
          依据绝对酌情权，要求交易员进行干预、对下单进行核准以及或终止有关客户的账户。对于由套利及或操控所产生的任何纠纷，$
          {platformTitle}将依据绝对酌情权进行解决。{platformTitle}
          可依据酌情权决定向任何相关监管机构或执法机构报告有关事件。于此处所陈述的任何行动或决定并不免除或损害$
          {platformTitle}对客户和其雇员拥有之任何权利或赔偿。
        </p>
        <h3>抵押协议</h3>
        <p>
          客户的所有资金、货币及其它财产，如为{platformTitle}
          在任何时候代客户持有、掌管或控制，此类财物将被{platformTitle}
          作为担保物，并可因客户对{platformTitle}
          的义务受限制于普通留置权及对冲权。{platformTitle}
          可在不通知客户的情况下行使其绝对酌情权在任何时候将客户的所有资金、货币或其他财产投入或转进客户的任何账户。客户亦授权$
          {platformTitle}
          以单独地或者与其他客户的财产一起的形式，将客户作为保证金或担保物的任何财产进行抵押、再抵押、投资或出借于$
          {platformTitle}自身或其他方。此授权适用于所有{platformTitle}
          为客户开持的账户，并在客户全额付清所有债务之前、或{platformTitle}
          发出撤销通知之前有效。
        </p>
        <h3>清算日与延展</h3>
        <p>
          客户的所有合约产品持仓会在交易日以美元显示在客户的账户上，并在48小时内清算，并以美元作为交收货币。持仓款项会在额外48小时后自动过仓，除非
        </p>
        <p>
          （i）客户给予令{platformTitle}满意的指示交割，这要按照$
          {platformTitle}的惯例、通常收费和重新交付费用；
        </p>
        <p>
          （ii）{platformTitle}
          接受了客户的下单可有全权酌情决定对冲合约产品持仓。客户应在合约产品清算日的前一个工作天正午之前指示是否交割或对冲。如无客户的及时指示，$
          {platformTitle}
          将被视为得到客户的授权全权及酌情地决定是否延展所有或任何客户在$
          {platformTitle}账户中的货币持仓。一切相关风险均由客户承担。
        </p>
        <h3>录音</h3>
        <p>
          客户同意{platformTitle}
          有权采取录音程序录取及用作确实客户指示或通讯。客户进一步同意在涉及任何客户与$
          {platformTitle}
          的纠纷或诉讼中，任何一方可以使用此类录音或眷本作证据。客户理解并同意$
          {platformTitle}可定期根据其采用的营业模式清除这类录音。
        </p>
        <h3>第三方操控者</h3>
        <p>
          {platformTitle}绝对不允许客户将交易授权或对其账户的管理交予第三者,
          不论是以自主权或非自主权的基础上。
        </p>
        <h3>客户声明及承诺</h3>
        <p> i.客户本人是头脑健全、达法定年龄、并具有法律能力；</p>
        <p>ii.只有账户持有人/客户本人享有其账户之利益；</p>
        <p>iii.客户有足够投资经验、及财政能力承受合约产品交易上的风险；</p>
        <p>
          iv.客户并不受雇于任何交易所、交易所持有绝大部分资本的公司、交易所的成员及或在交易所注册的公司、银行、信托机构或保险公司;
        </p>
        <p>v.客户提供的信息(包括其一切个人资料)皆为真实、准确和完整;</p>
        <p>
          vi.客户将完全遵守当地有关法例，包括为遵守该等地区或司法管辖区内须遵守之规定辨理一切必要手续以取得政府或其他方面之同意，以及因为使用本平台进行交易而需要支付当地的任何税项、关税及其他金额；
        </p>
        <p>vii.客户不会进行任何套利或操控的活动;</p>
        <p>
          viii.明白本协议的所有条款，包括但不限于上述客户告鉴、风险披露声明;
        </p>
        <p>ix.客户明白及同意执行客户根据本协议的一切义务、责任及承诺;</p>
        <p>
          x.若上述任何声明及承诺因任何原因出现不正确，客户有责任立即以书面形式通知$
          {platformTitle}。
        </p>
        <h3>密码保护</h3>
        <p>
          客户必须将{platformTitle}
          提供给客户的密码保密并确保没有第三者取用其交易设施。客户同意对所有经由电邮、电子交易平台、电话或书面向$
          {platformTitle}
          发出的指示确实负责，即使是由第三者发出，只要这些指示已和客户密码或客户签名和账户号码认证，并根据$
          {platformTitle}的判断相信这是客户表面授权。{platformTitle}
          并没有责任对这些表面授权作进一步查询，也没有责任对因为依据这些表面授权所采取的或不采取的行动所造成之后果负责。客户须对密码的保密性、安全性及其使用独自承担责任。
        </p>
        <h3>免责条款</h3>
        <p>
          i.客户明白、理解及同意{platformTitle}
          将不会因本协议(包括但不限于上述客户告鉴、风险披露声明)中所述的任何一项或多项风险引至客户的一切损失（不论是直接或间接的）承担任何责任。
        </p>
        <p>
          ii.客户明白、理解及同意{platformTitle}将不会对客户因{platformTitle}
          行驶或不行驶或如何行驶其任何酌情权或根据此协议
          (包括但不限于在发现套利或操控活动的情况下) 给予{platformTitle}
          的权利而受到的一切损失（不论是直接或间接的）承担任何责任。
        </p>
        <p>
          iii.客户明白、理解及同意{platformTitle}
          将不会对客户在本协议(包括但不限于上述客户告鉴、风险披露声明)中帮助由客户自行负责的损失及或责任（不论是直接或间接的）承担任何责任。
        </p>
        <p>
          iv.报价错误,当某些报价或成交价出现错误时，{platformTitle}
          将不为此错误所导致的账户结余相应错误负责。有关错误可能包括但不限于：交易员的错误报价、非国际市场价之报价、或是任何报价错误（例如由于硬件、软件或通讯或系统网络故障导致报价错误或者第三者提供了错误的数等等）。$
          {platformTitle}
          不需为错误所导致的账户余额负责。客户下单时需预留足够的时间执行订单和系统计算所需保证金的时间。当订单的执行价格或订单设定和市场价格过于接近，这可能会触发其他订单（不论是那种订单类型）或发出保证金提示。$
          {platformTitle}
          不会对由于系统没有足够时间执行订单或进行运算所产生的保证金提示、账户结余或账户持仓负责。上文不得视作内容尽列。一旦发生报价或执行错误，$
          {platformTitle}
          保留对有关账户进行必要更正或调整的权利。对于报价或执行错误而产生的任何争议，$
          {platformTitle}将拥有及依据绝对酌情权进行解决。若因此带给$
          {platformTitle}任何损失、损害或责任，客户同意予以赔偿使$
          {platformTitle}不受损害。
        </p>
        <p>
          v.所有客户必须意识到任何回报是非保证的。此外，{platformTitle}
          不对任何{platformTitle}、其雇员、及/
          或任何其他人士作出的指称或保证负责。
        </p>
        <p>
          vi.{platformTitle}将不负责因传输或通讯设施故障、电力短路或任何其他$
          {platformTitle}所不能控制或预计的原因带来的指令传输的延迟。 $
          {platformTitle}将仅对直接因为{platformTitle}
          的蓄意过错或欺诈造成的行为负责。{platformTitle}
          将不对其他方面过错而引起的损失负责。
        </p>
        <h3>通讯联络</h3>
        <p>
          报告、报表、通讯及其它通知可能送达至客户于开户申请时所提供的电邮地址或住址或客户不时以书面或网上登入方式向$
          {platformTitle}
          更新的电邮地址或住址。所有如此送出的报告、报表、通讯及其它通知，不论是邮寄、电邮或其他方式，一旦投入有关邮政机构，或经发送机构接收，即被认定已由$
          {platformTitle}发出，且被认定已送达客户本人，不论客户是否实际收到。
        </p>
        <h3>报表与确认</h3>
        <p>
          订单的确认报告（如日结单、月结单等）和客户的账户报表将被视作正确、终结、并对客户具有约束力，除非客户在收到$
          {platformTitle}
          网上交易平台或其他方式送达的报告两日之内立即作出书面反对。
        </p>
        <h3>协议修改</h3>
        <p>
          客户同意{platformTitle}
          有权可不时修改或变更本协议之任何条款或增补任何新条款。
          {platformTitle}
          会把这些修改、变更或增补在其公司网站公布及通知客户。客户须定时浏览有关条款之修改。客户在有关修改、变更或增补在$
          {platformTitle}
          网站公布后作出的任何买卖或交易指令将被视为同意及接受此有关修改、变更或增补的行为。除非有关修改、变更或增补是以上述形式由$
          {platformTitle}修订或由客户和{platformTitle}
          共同签字作实，本协议的任何条款均不可被豁免或被修订。在任何情况下，$
          {platformTitle}未能坚持其在本协议的权利均不可被解释为{platformTitle}
          对该权利的放弃。任何口头协议或指示均不可被承认或执行。
        </p>
        <h3>赔偿</h3>
        <p>
          客户同意，如因客户未能完全与及时地履行其承诺或因其声明或保证并不属实或正确，而给$
          {platformTitle}
          带来任何债务、损失、损害、成本或费用（包括律师费），客户将对此向$
          {platformTitle}作出百分百的赔偿，并使之不受损害。
        </p>
        <h3>权利转让</h3>
        <p>
          {platformTitle}
          可在无需客户同意或批准的情况下，将本协议之全部或部分权利或义务授予任何人。
        </p>
        <h3>终止</h3>
        <p>
          本协议将持续有效直至终止。客户可在任何时候终止本协议，但客户必须没有未平仓现货合约产品持仓及不对$
          {platformTitle}负有任何债务，且{platformTitle}
          实际收到终止协议的书面通知。{platformTitle}
          也可任何时候向客户提出终止协议的书面通知，并在发出通知当日收市随后第一个结算时间起开始生效。上述本协议的终止将不影响任何之前签订的交易且不解除任何一方在此协议下的任何义务，或解除客户的任何债务责任。
        </p>
        <h3>信用状况</h3>
        <p>
          客户授权{platformTitle}，或其雇员以{platformTitle}
          的名义，调查客户的信用状况并与有关的银行、金融机构和信用机构查证及确定客户资料。客户进一步授权$
          {platformTitle}调查其目前和过去的投资活动。
        </p>
        <h3>管辖法律及司法管辖权</h3>
        <p>本协议根据加拿大的法律解释与执行。</p>
        <h3>协议的整体和全部内容</h3>
        <p>
          本协议连同开户申请时参阅文件和有关附件构成本协议主题整体和全部内容。本协议将取代所有过去经双方签署或承诺的与本协议主题，杠杆式合约产品交易相关的书面或口头协议。
        </p>
      </div>
    );
  }, [platformTitle]);
  const policyHTML = useMemo(() => {
    return (
      <div className="wrapper">
        <div className="bar">
          <h2>隐私政策</h2>
          <span className="close" onClick={() => setRules("")}></span>
        </div>
        <p>
          {platformTitle}
          的个人资料私隐政策是根据加拿大私隐条例保护客户权益，隐私政策规定了我们只通过您登录本平台、在本平台上注册和/或使用我们提供的服务采集您信息的类型以及我们如何使用和保护这些采集到的信息。
        </p>
        <p>
          1、为确保您对我们在处理个人资料上有充分信心,请您务必详细阅读及理解隐私政策的条文。特别是您一旦登录本平台不论您是否在本平台上注册，均向我们表明您接受、同意、承诺和确认:
        </p>
        <p>
          1.1您具有完全民事权利能力和行为能力，或虽不具有完全民事权利能力和行为能力,但点击同意注册按钮，$
          {platformTitle}即视为经其法定代理人同意并由其法定代理人代理使用$
          {platformTitle}的服务；
        </p>
        <p>1.2您在自愿下同意向我们披露个人资料;</p>
        <p>1.3您会遵守本隐私政策的全部条款和限制;</p>
        <p>
          1.4您同意我们通过您登录本平台、在本平台上注册和/或使用我们提供的服务收集您的信息；
        </p>
        <p>
          1.5您同意日后在无需另行通知您的情况下对隐私政策的任何修改，请定期浏览本网页查阅最新条款;
        </p>
        <p>
          1.6您同意我们的分公司、附属公司、雇员、就您可能会感兴趣的产品和服务与您联络(除非您已经明确表示不想收到该等讯息)。
        </p>
        <p>
          2、当您使用本平台时，您同意我们开始使用cookies来追踪您的每一个动作，并收集和记住你留下的所有信息，其中包括但不限于您的IP地址，地理位置及其他资料。
          如果您愿意使用本平台提供的服务，您需要填写和提供以下两种信息：
        </p>
        <p>
          2.1身份信息。该信息可以帮助我们验证您是否有资格注册为本平台会员，包括但不限制你的名字，你的居住地址，邮寄地址，您所属国家或政府发签发的其他具有证明您身份信息的不同的证书以及涉及到的号码及所有其他可帮助我们验证您身份的信息。
        </p>
        <p>
          2.2
          服务信息。该信息帮助我们与您联系并顺利为您提供服务，包括但不限制您的电话号码、传真号码、有效的电子邮件地址、邮寄地址以及您的借记卡信息和/或其他帐户信息（以下合称为“服务信息”）。
        </p>
        <p>
          3、在您使用本平台或其提供的服务时，我们可能会为了改进本平台的使用功能，提升您对使用本平台及其提供的服务及其安全性的体验或是根据法院或可适用法律法规或有管辖权的其他政府机构的命令，通过本平台公示的专属于我们的邮箱或其他我们认为合规的方式收集更多的必要的信息。
        </p>
        <p>
          4、如果您访问了任何链接在本平台上的其他第三方平台或合作的第三方，您应同意并遵守该第三方网单独和独立的隐私政策。我们对这些平台或合作方的内容及活动不承担任何责任。
        </p>
        <p>
          5、我们可能会在客户的电脑设定及存取{platformTitle}
          Cookies，以协助我们分析并了解有关之市场推广策略的效果。$
          {platformTitle}及其雇员可能会于提供的产品和服务使用Cookies
          来追踪客户在其网站的浏览，Cookies常用于记录访问者浏览我们平台上的各个项目时的习惯以及偏好。Cookies所搜集的资料是不记名的集体统计数据,不载有个人资料。
        </p>
        <p>
          6、{platformTitle}
          致力于确保其网站是安全及符合业界标准，并且使用其他资料保障工具，例如：防火墙、认证系统（密码和个人身份证号码等）和操控机制来控制未获授权的系统进入网站和存取资料；
        </p>
        <p>
          7、我们不会向未获授权人士透露任何资料，但不包括以合约形式协助$
          {platformTitle}
          提供便利的平台服务予客户的公司，协助准备和邮寄月结单，执行必要的维护和开发等工作，只要这些当事方同意将这些信息保密。当我们相信披露信息是适当的，是遵守法律、法规、规章制度的或来自于法院或他主管当局的命令、执行我们的平台策略，正常运行平台所需要，或保护我们或他人的权利、财产或安全的。
        </p>
      </div>
    );
  }, [platformTitle]);
  return (
    <Layout className="flex-container flex-center">
      <div className={styles.wrapper}>
        <div className="form flex-block">
          <Formik
            innerRef={formRef}
            initialValues={{
              mobilePhone: "",
              password: "",
              captcha: "",
            }}
            validationSchema={yup.object().shape({
              mobilePhone: yup
                .string()
                .required("请输入手机号码")
                .matches(Utils.validate.phone, "请输入正确的手机号码"),
              password: yup
                .string()
                .required("请输入密码")
                .matches(
                  Utils.validate.password,
                  "密码为6-16位字符，必须包含字母和数字"
                ),
              captcha: yup
                .string()
                .required("请输入验证码")
                .matches(Utils.validate.captcha, "请输入正确的验证码"),
            })}
            onSubmit={(values) => {
              createRealAccount(values);
            }}
          >
            {({
              handleSubmit,
              touched,
              values,
              errors,
              setFieldValue,
              isValid,
              dirty,
              setFieldError,
            }) => (
              <form onSubmit={handleSubmit}>
                <h2 className="page-title">开户</h2>
                <div
                  className={`${
                    (values.mobilePhone || touched.mobilePhone) &&
                    errors.mobilePhone
                      ? "err"
                      : ""
                  } item`}
                >
                  <div className={`form-item`}>
                    <i className="icon icon-phone"></i>
                    <input
                      type="text"
                      placeholder="请输入手机号"
                      maxLength={11}
                      onChange={(e) => {
                        // setTimeout(validPhone, 0);
                        setFieldValue("mobilePhone", e.target.value);
                      }}
                    />
                  </div>
                  {(values.mobilePhone || touched.mobilePhone) &&
                    errors.mobilePhone && (
                      <div className="error">{errors.mobilePhone}</div>
                    )}
                </div>
                <div
                  className={`${
                    (values.password || touched.password) && errors.password
                      ? "err"
                      : ""
                  } item`}
                >
                  <div className={`form-item`}>
                    <i className="icon icon-password"></i>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="请输入密码"
                      maxLength={16}
                      onChange={(e) =>
                        setFieldValue("password", e.target.value)
                      }
                    />
                    <span onClick={() => setPasswordVisible(!passwordVisible)}>
                      <i
                        className={`icon icon-see ${
                          passwordVisible ? "active" : ""
                        }`}
                      ></i>
                    </span>
                  </div>
                  {(values.password || touched.password) && errors.password && (
                    <div className="error">{errors.password}</div>
                  )}
                </div>
                <div
                  className={`${
                    (values.captcha || touched.captcha) && errors.captcha
                      ? "err"
                      : ""
                  } item`}
                >
                  <div className={`form-item`}>
                    <i className="icon icon-very"></i>
                    <input
                      type="text"
                      placeholder="请输入验证码"
                      maxLength={4}
                      onChange={(e) => setFieldValue("captcha", e.target.value)}
                    />
                    <CutDown
                      className="text-btn"
                      title="获取验证码"
                      onClick={(validate) => {
                        !errors.mobilePhone &&
                          sendPhoneCaptcha(values.mobilePhone, validate);
                      }}
                      reset={() => setSendCode(false)}
                      flag={sendCode}
                      disabled={errors.mobilePhone || !values.mobilePhone}
                    />
                  </div>
                  {(values.captcha || touched.captcha) && errors.captcha && (
                    <div className="error">{errors.captcha}</div>
                  )}
                </div>

                <p className="tips">
                  <span className="custom-checkbox">
                    <input type="checkbox" checked readOnly />
                    <i></i>
                  </span>
                  我已年满18周岁，并同意
                  <span onClick={() => showRules("agreement")}>
                    《客户协议》
                  </span>
                  与
                  <span onClick={() => showRules("policy")}>《隐私政策》</span>
                </p>
                <div className={styles.tips_message}>
                  {tips.msg && <p className={`${tips.type}`}>{tips.msg}</p>}
                </div>
                <div className="tools">
                  <button
                    type="submit"
                    disabled={isSubmiting}
                    className="block-show btn btn-register"
                  >
                    {isSubmiting ? "提交中..." : "立即领取"}
                  </button>
                </div>
                 
              </form>
            )}
          </Formik>
        </div>
        {rules === "agreement" && (
          <div className={styles.modal}>
            {state.agreementHTML ? (
              <div className="wrapper">
                <div className="bar">
                  <h2>客户协议</h2>
                  <span className="close" onClick={() => setRules("")}></span>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: state.agreementHTML }}
                ></div>
              </div>
            ) : (
              agreementHTML
            )}
          </div>
        )}
        {rules === "policy" && (
          <div className={styles.modal}>
            {state.agreementHTML ? (
              <div className="wrapper">
                <div className="bar">
                  <h2>隐私政策</h2>
                  <span className="close" onClick={() => setRules("")}></span>
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: state.policyHTML }}
                ></div>
              </div>
            ) : (
              policyHTML
            )}
          </div>
        )}
      </div>
    </Layout>
  );
};
