import React, { useState, useCallback } from "react";
import styles from "./styles.module.scss";

export default () => {
  const [state, setState] = useState(true);

  const toggle = useCallback(() => {
    setState(!state);
  }, [state]);

  return state && (
    <div className={styles.bar}>
      <div className="close" onClick={toggle} hidden={true}></div>
    </div>
  )
};
