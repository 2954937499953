import React from "react";
import { useParams } from "react-router-dom";
import classnames from "classnames";
import ClientAgreement from "../components/ClientAgreement";
import PrivacyPolicy from "../components/PrivacyPolicy";
import Disclaimer from "../components/Disclaimer";
import RiskDisclosure from "../components/RiskDisclosure";

import styles from "./index.module.scss";


export default () => {
  const { pageType } = useParams();
  return (
    <div>
      <div className={classnames("header-bg", styles.nav)}>
       
      </div>
      <div className={classnames("container sm-size", styles.page_wrap)}>
        {pageType  === "client_agreement" && <ClientAgreement />}
        {pageType  === "privacy_policy" && <PrivacyPolicy />}
        {pageType  === "disclaimer" && <Disclaimer />}
        {pageType  === "risk_disclosure" && <RiskDisclosure />}
      </div>
    </div>
  );
};

 
