import React, { useCallback } from "react";
import styles from "./KycSuccess.module.scss";
import { linkConfig } from "../../config";

/**
 * 立即下载会依据视窗宽度 (<1080px) 决定展示 QRCode 或是执行下载函示
 * 下载函示里面又会依据设备类别：
 * 安卓设备执行下载轮询
 * 苹果设备跳转至h5
 */
export default (props) => {
  const toCustom = useCallback(() => {
    props.toggleVisible();
    window.location.href = linkConfig.online;
  }, [props]);

  const handleButtonClick = () => {
    toCustom();
  };

  return (
    <div className={styles.modal}>
      <div className={styles.success}>
        <h2>资料完善成功</h2>
        <p>
          <b>50元</b>红包已发放至您账户
        </p>
        <div className={styles.btn_box}>
          <span className={styles.button1} onClick={handleButtonClick}>
            领取红包
          </span>
        </div>
      </div>
    </div>
  );
};
