function isLocalHost(hostname = window.location.hostname) {
  return ['localhost', '127.0.0.1', '', '::1'].includes(hostname)
}

function createLinkConfig () {
  let linkConfig = {
    online: "https://shunlong.s5.udesk.cn/im_client/?web_plugin_id=1528&group_id=1046",
    disclaimer: "/singlepage/disclaimer",
    risk: "/singlepage/risk_disclosure",
    agreement: "/singlepage/client_agreement",
    policy: "/singlepage/privacy_policy"
  };

  /**
   * 本地开发或者UAT 设定:
   * isLocalHost() || window.location.hostname.includes("atbgovernor")
   * 
   * 其余视为正式环境，动态域名
   */
  if (isLocalHost() || window.location.hostname.includes("atbgovernor")) {
  } else {
  }

  return linkConfig;
}

const linkConfig = createLinkConfig();
const sourceUrl = "_s_u_l_";
export { linkConfig, sourceUrl };
