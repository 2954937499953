import React from "react";
export default () => {
    return <div>
        <h2>风险披露</h2>
        <h3>市场风险和网上交易</h3>
        <p>合约产品交易涉及相当大的风险，其并非对每一位投资者都适合。请详细阅读此风险披露声明和本协议所有内容。虽然网上交易为客户带来很多方便及效率，但它可能增加外汇交易本身的风险。因此客户在交易前，须准备承担一切有关风险。</p>
        <h3>只适合专业投资人士</h3>
        <p>合约产品交易仅适合专业机构或投资经验丰富的人士，其财政状况能够承受也许远超过保证金或存款金价值的损失。</p>
        <h3>交易设施及责任限度</h3>
        <p>合约产品的业务并不需公开喊价。虽然许多场所交易都以计算机为基础的系统提供报价和实际价格，但这两价格可能会因为市场不流动性而有所偏差。大部分电子交易设施是由以计算机为基础的系统来支援进行交易下单、执行、配对。该类系统易受到临时系统故障的影响。客户收回某些损失的能力可能受限于系统提供者、银行及／或其他金融机构或清算所等所设定的不同程度责任限度。</p>
        <h3>交易所场外交易</h3>
        <p>在合约产品市场交易上，我司可被允许进行场外交易。我司有权拒绝接受或保证任何订单。鉴于这些原因，平仓、评估价值、确定公平价格或评估风险可能很困难或不可能。再者场外交易可能受到较少的监管或受管于不同的监管体系。客户在开始交易之前，应该清楚了解适用的规定和相关风险。</p>
        <h3>杠杆式效应</h3>
        <p>合约产品交易是带有很高的风险。此类交易属于杠杆式交易，意指透过杠杆比例，借以让客户可以极低的初始保证金来参与金额极高的交易合约。即使市场上出现任何变动也会对客户的投资造成极大的影响，虽然这对客户也许有好的影响，但也有可能是坏的。客户有可能会为了保持持仓不被结算而需要承受客户存入我司的初始保证金及任何追加资金上的损失。如果市场变动对客户不利或者保证金水平提高，客户更有机会未能及时追加保证金来维持客户的持仓而在亏损的情况下被强制平仓。客户将必须对由此造成的亏损自行负责。</p>
        <h3>止损限价指令</h3>
        <p>损失限制在特定金额的指令（例如“止损限价”指令）有可能并不见效或无法执行。如果订单是停损限价单，我司无法保证订单能以限价执行或能成功地执行。此外，一些使用持仓合并的策略, 例如差价或同价对冲与做“长仓”或“短仓”存在有相同的风险。</p>
        <h3>电子交易</h3>
        <p>通过电子交易系统进行的交易，在传送和接收客户指示或其他资料时可能出现延误、故障或传输中断。系统故障更可能造成客户的订单难以或无法按照客户的指示执行，甚至错误地执行。客户需接受电子交易系统带来的相关风险。</p>
        <h3>暂停或限制交易与定价的关系</h3>
        <p>市场状况及某些市场的运作条例（包括停市或合约产品暂停交易）也可能导致完成交易或平仓等交易指令有困难或不可能执行，因而增加客户损失。此外，相关资产与合约产品的正常价格关系可能不存在。由于缺乏相关资产的参考价格，评估或确定“公平”价格也变得困难或不可能。</p>
        <h3>在其他司法管辖区的交易</h3>
        <p>在其他司法管辖区市场（包括正式连接到本地市场的市场）进行的交易可能使客户需面对更多的风险。在那些市场的规定下，客户可能受到不同或较低的保障。在开始交易前客户应该询问任何与客户交易有关的规定，并了解本身所在地及其他司法管辖区可得到的保障。</p>
        <h3>交易对手的信誉</h3>
        <p>我司是不会保证客户的交易对手的信誉。我司只会尽力与有良好声誉及可靠的机构和清算所进行交易。此外，客户必须理解及明白有可能因为交易流动性的问题，使客户不能及时为亏损的持仓进行平仓，并为客户带来相当大的损失。</p>
        <h3>保证金及追加保证金</h3>
        <p>我司的保证金政策可能要求客户提供追加资金以便维持其保证金达至我司的指定水平及要求。客户有义务不时并及时满足这类保证金要求，否则其持仓将可能被自动平仓并造成相应的损失。我司保留有权拒绝接受订单或提供市场对冲。</p>
        <h3>汇率变动风险</h3>
        <p>我司之交易账户是以美元或我司不时同意之其他货币为单位。倘若客户以美元以外之其他货币存取款，客户必须承担因汇价变动而导致之任何收益或损失。客户并同意我司可按其全权决定的形式及时间对货币作出任何兑换，以实行其根据本协议而采取之任何行动或权利。</p>
        <h3>有关法例</h3>
        <p>客户应完全遵守当地有关法例，并同意付责因使用我司平台进行的交易而需支付当地任何相关税项、关税及其他金额。客户在我司平台进行交易将被视为其对我司发出声明及保证遵守当地法律及规定。</p>
        <h3>咨询法律及其它独立的专业意见</h3>
        <p>上述声明并不披露关于合约产品的所有风险及其交易上的一切重要事项。客户在开始进行合约产品交易之前，应该清楚了解有关交易性质和风险程度，并知悉合约产品交易并不适合一般投资者。客户必须根据其个人的投资经验、目的、财政状况和风险承受能力等相关因素仔细考虑合约产品交易是否适合客户本人。我司极力建议所有客户在开户及开始交易前应该咨询法律及其它独立的专业意见。</p>
    </div>
}