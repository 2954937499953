import React from "react";
export default () => {
  return (
    <div>
      <h2>免责声明</h2>
      <p>
        本网站的资料，只作一般性质及说明用途。对该等资料，我司不会就任何错误、遗漏、或错误陈述或失实陈述（不论明示或默示的）承担任何责任。对任何因使用或不当使用或依据我司所载的资料而引致或所涉及的损失、毁坏或损害
        （包括但不限于相应而生的损失、毁坏或损害），我司概不承担任何义务、责任或法律责任。
      </p>
      <p>
        为方便使用者查阅由其他人士/团体或通过其他人士/团体提供的资讯，本网站可能会提供或协助提供超链接至外界网站。提供或协助提供该等超链接，并不构成我司就赞同或不赞同该等网站之内容作出任何明示或暗示的声明、陈述或保证；对任何因使用或不当使用或依据通过我司网站传递或提供的外界网站的内容而引致或所涉及的损失、毁坏或损害（包括但不限于相应而生的损失、毁坏或损害），我司概不承担任何义务、责任或法律责任。
      </p>
      <p>
        本网站所提供的所有资料均为我司之财产。任何人士如未经我司预先书面授权同意，不得以任何理由及形式散发此网站的文字或图档或音像档或在阁下之伺服器内重制、修改或重用此系统内容的文字及图档或音像档。阁下可以打印或储存有关资料于个人电脑内，以供个人使用或参考用途，但须同时保留内容所载的所有版权及其他专有通告。
      </p>
      <p>
        本网站并非供禁止发布或提供本网站的任何司法管辖区内的任何人士使用（基于该名人士的国籍、居留地或其他原因）。有关禁制的人士不得使用本网站。使用本网站所载资料的任何人士有责任遵守其有关司法管辖区所有的适用法例及规例。阁下继续使用本网站，即代表阁下陈述及保证阁下的司法管辖区的适用法例及规例容许阁下浏览有关资料。
      </p>
      <p>
        本网站不应视为向任何司法管辖地区内的任何人士提出其进行买卖任何产品或服务的要约或邀请，即使这些行为可能于相关地区被视为非法行为。
      </p>
      <p>
        保证金交易及买卖具一定风险，价格可升可跌，阁下需知悉并了解相关风险。
      </p>
      <p>
        当阁下一旦使用本网站，即表示阁下无条件接受上述所载的免责条款以及任何我司不时在无须预先通知阁下的情况下而对上述条款所作出的修改及/或修订。请定期浏览此网页查阅任何修改及/或修订。
      </p>
    </div>
  );
};
