/**
常用正则表达式
 */
export default {
  validate: {
    // 手机号
    phone: /^(((1[3456789][0-9]{1})|(15[0-9]{1})|111)+\d{8})$/,
    //  用户密码
    password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,16}$/,
    //电子邮箱
    // eslint-disable-next-line no-useless-escape
    email: /^(?!\s+)[A-Za-z0-9_\-\.]+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/,
    // 手机验证码
    captcha: /^\d{4}$/,
    // 中文名
    chineseName: /^[\u4e00-\u9fa5]{1,15}$/,
    // 证件类型
    idDocumentNumber: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
  },
  downloadNow: () => {
    let agent = navigator.userAgent.toLowerCase();
    let android = agent.indexOf("android");
    let iphone = agent.indexOf("iphone");
    let ipad = agent.indexOf("ipad");
    let equipmentType = "";
    if (android !==-1) {
      equipmentType = "android";
    }
    if (iphone !==-1 || ipad !==-1) {
      equipmentType = "ios";
    }
    const hrefConfig = {
      'ios': '',
      'android': '', // sogou
    }
    window.location.href = hrefConfig[equipmentType];
  },
};

/**
 * 设置用户本地状态
 * @param {string} key
 * @param {string} value
 * @param {object} option
 */
export const setSessionStore = (key, value) => {
  if (window.sessionStorage) {
    return window.sessionStorage.setItem(key, JSON.stringify(value));
  }
};
export const getSessionStore = (key) => {
  if (window.sessionStorage) {
    const value = window.sessionStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  }
  return null;
};
export const removeSessionStore = (key) => {
  if (window.sessionStorage) {
    window.sessionStorage.removeItem(key);
  }
};

export const getAgeByIDCard = function (identityCard) {
  var len = (identityCard + "").length;
  var strBirthday = "";
  if (len === 18) {
    //处理18位的身份证号码从号码中得到生日和性别代码
    strBirthday =
      identityCard.substr(6, 4) +
      "/" +
      identityCard.substr(10, 2) +
      "/" +
      identityCard.substr(12, 2);
  }
  if (len === 15) {
    var birthdayValue = "";
    birthdayValue = identityCard.charAt(6) + identityCard.charAt(7);
    if (parseInt(birthdayValue) < 10) {
      strBirthday =
        "20" +
        identityCard.substr(6, 2) +
        "/" +
        identityCard.substr(8, 2) +
        "/" +
        identityCard.substr(10, 2);
    } else {
      strBirthday =
        "19" +
        identityCard.substr(6, 2) +
        "/" +
        identityCard.substr(8, 2) +
        "/" +
        identityCard.substr(10, 2);
    }
  }
  //时间字符串里，必须是“/”
  var birthDate = new Date(strBirthday);
  var nowDateTime = new Date();
  var age = nowDateTime.getFullYear() - birthDate.getFullYear();
  //再考虑月、天的因素;.getMonth()获取的是从0开始的，这里进行比较，不需要加1
  if (
    nowDateTime.getMonth() < birthDate.getMonth() ||
    (nowDateTime.getMonth() === birthDate.getMonth() &&
      nowDateTime.getDate() < birthDate.getDate())
  ) {
    age--;
  }
  return age;
};
