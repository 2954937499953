
import React from "react";
export default () => {
    return <div>
        <h2>隐私政策</h2>
        <p>我司的个人资料私隐政策是根据加拿大私隐条例保护客户权益，隐私政策规定了我们只通过您登录本平台、在本平台上注册和/或使用我们提供的服务采集您信息的类型以及我们如何使用和保护这些采集到的信息。</p>
        <p>1、为确保您对我们在处理个人资料上有充分信心,请您务必详细阅读及理解隐私政策的条文。特别是您一旦登录本平台不论您是否在本平台上注册，均向我们表明您接受、同意、承诺和确认: </p>
        <p>1.1您具有完全民事权利能力和行为能力，或虽不具有完全民事权利能力和行为能力,但点击同意注册按钮，我司即视为经其法定代理人同意并由其法定代理人代理使用我司的服务；</p>
        <p>1.2您在自愿下同意向我们披露个人资料;</p>
        <p>1.3您会遵守本隐私政策的全部条款和限制;</p>
        <p>1.4您同意我们通过您登录本平台、在本平台上注册和/或使用我们提供的服务收集您的信息；</p>
        <p>1.5您同意日后在无需另行通知您的情况下对隐私政策的任何修改，请定期浏览本网页查阅最新条款;</p>    
        <p>1.6您同意我们的分公司、附属公司、雇员、就您可能会感兴趣的产品和服务与您联络(除非您已经明确表示不想收到该等讯息)。</p>
        <p>2、当您使用本平台时，您同意我们开始使用cookies来追踪您的每一个动作，并收集和记住你留下的所有信息，其中包括但不限于您的IP地址，地理位置及其他资料。
如果您愿意使用本平台提供的服务，您需要填写和提供以下两种信息：</p>
        <p>2.1身份信息。该信息可以帮助我们验证您是否有资格注册为本平台会员，包括但不限制你的名字，你的居住地址，邮寄地址，您所属国家或政府发签发的其他具有证明您身份信息的不同的证书以及涉及到的号码及所有其他可帮助我们验证您身份的信息。</p>
        <p>2.2 服务信息。该信息帮助我们与您联系并顺利为您提供服务，包括但不限制您的电话号码、传真号码、有效的电子邮件地址、邮寄地址以及您的借记卡信息和/或其他帐户信息（以下合称为“服务信息”）。</p>
        <p>3、在您使用本平台或其提供的服务时，我们可能会为了改进本平台的使用功能，提升您对使用本平台及其提供的服务及其安全性的体验或是根据法院或可适用法律法规或有管辖权的其他政府机构的命令，通过本平台公示的专属于我们的邮箱或其他我们认为合规的方式收集更多的必要的信息。</p>
        <p>4、如果您访问了任何链接在本平台上的其他第三方平台或合作的第三方，您应同意并遵守该第三方网单独和独立的隐私政策。我们对这些平台或合作方的内容及活动不承担任何责任。</p>
        <p>5、我们可能会在客户的电脑设定及存取我司 Cookies，以协助我们分析并了解有关之市场推广策略的效果。我司及其雇员可能会于提供的产品和服务使用Cookies 来追踪客户在其网站的浏览，Cookies常用于记录访问者浏览我们平台上的各个项目时的习惯以及偏好。Cookies所搜集的资料是不记名的集体统计数据,不载有个人资料。</p>
        <p>6、APP将收集设备标识信息（IDFA），用于数据统计和内容服务，如果同意该服务，请用户自行勾选授权。</p>
        <p>7、我司致力于确保其网站是安全及符合业界标准，并且使用其他资料保障工具，例如：防火墙、认证系统（密码和个人身份证号码等）和操控机制来控制未获授权的系统进入网站和存取资料；</p>
        <p>8、我们不会向未获授权人士透露任何资料，但不包括以合约形式协助我司提供便利的平台服务予客户的公司，协助准备和邮寄月结单，执行必要的维护和开发等工作，只要这些当事方同意将这些信息保密。当我们相信披露信息是适当的，是遵守法律、法规、规章制度的或来自于法院或他主管当局的命令、执行我们的平台策略，正常运行平台所需要，或保护我们或他人的权利、财产或安全的。</p>
    
    </div>
}